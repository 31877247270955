import { AttendantRole } from "./GasStation";

export interface UserInterface {
  documentType: String;
  documentIssueDate: any;
  expirationDate: any;
  dayOfBirth: any;
  personToNotify: any;
  id?: string;
  displayName: string;
  email: string;
  SupervisorId?: String;
  city: string;
  profilImageUrl?: string;
  cnibImageUrl?: string;
  password?: string;
  confirmPassword?: string;
  phoneNumber: string;
  cnibNumber?: string;
  gender?: string;
  availableZone?: string;
  dealerId?: string;
  supervisorId?: string;
  agencyId?: string;
  affiliateAgentId?: string;
  pinCode: string;
  pointOfCollectIdentity?: string;
  pointOfSaleIdentity?: string;
  active: boolean;
  isCertify: boolean;
  isVipAccount: boolean;
  accountCapAmount: string;
  isSeller?: boolean;
  userRole: UserRole;
  userAgencySubRole?: AgencyStaffRole;
  attendantRole?: AttendantRole;
  companyAttendantRole?: CompanyAttendantRole;
  adresse: string;
  profession: string;
  agentCode?: string;
  isAgrivoucherDealer?: boolean;
  isAffiliateToSupervisor?: boolean;
  reviewer?: boolean;
  isUserRoleGettingTransformed?: boolean;
  dealerReviewer?: boolean;
  canReceivePayment?: boolean;
  merchantCode?: string;
  gasStationCode?: string;
  agence: string;
  lat?: string;
  long?: string;
  lastName: string;
  firstName: string;
  createdAt: any;
  updatedAt: any;
  personToNotifyName: string;
  personToNotifyPhone: string;
}

export enum UserRole {
  SUDO = "SUDO",
  ADMIN = "ADMIN",
  MARCHAND = "MERCHANT",
  AGENT = "AGENT",
  DEALER = "DEALER",
  USER = "USER",
  GASSTATION = "GASSTATION",
  AGENCY = "AGENCY",
  AGENCYSTAFF = "AGENCYSTAFF",
  GASSTATIONSTAFF = "GASSTATIONSTAFF",
  SUPERVISOR = "SUPERVISOR",
  ONEAAGENT = "ONEAAGENT",
  // RESPONSABLEZONE = "RESPONSABLEZONE",
  SUPERMERCHANT = "SUPERMERCHANT",
  SUPERSTATION = "SUPERSTATION",
  COMPANY = "COMPANY",
  COMPANYSTAFF = "COMPANYSTAFF",
  TECHNICALSUPPORT = "TECHNICALSUPPORT",
  COLLECTOR = "COLLECTOR",
  COLLECTIONPOINT = "COLLECTIONPOINT",
}

export enum AccountType {
  MAIN = "MAIN",
  FUEL = "FUEL",
  TRANSACTIONFEESACCOUNT = "TRANSACTIONFEESACCOUNT",
  SNBLTRANSACTIONSFEESACCOUNT = "SNBLTRANSACTIONSFEESACCOUNT",
  ONEATRANSACTIONSFEESACCOUNT = "ONEATRANSACTIONSFEESACCOUNT",
}

export enum CardType {
  DEBIT = "DEBIT",
  PREPAID = "PREPAID",
}

export const mapsCardType = new Map<string, string>([
  ["DEBIT", "Débit"],
  ["PREPAID", "Prépayé"],
]);
export interface AccountInterface {
  id?: string;
  accountType: AccountType;
  balance: number;
}

export interface UserNotificationInterface {
  id: string;
  message: string;
  title: string;
  isReaded: boolean;
  createdAt: Date;
}

export enum AgencyStaffRole {
  MANAGER = "MANAGER",
  SELLER = "SELLER",
  CASHIER = "CASHIER",
  TECHNICALSUPPORT = "TECHNICALSUPPORT",
}

export const mapsGender = new Map<string, string>([
  ["MALE", "Homme"],
  ["FEMALE", "Femme"],
  ["NONBINARY", "Non binaire"],
  ["UNKNOWN", "Inconnu"],
]);
export const mapsSubRole = new Map<string, string>([
  ["MANAGER", "Manager"],
  ["SELLER", "Commerciale"],
  ["CASHIER", "Caissière"],
  ["TECHNICALSUPPORT", "Support technique"],
]);
export const mapsUserRole = new Map<string, string>([
  ["AGENCYSTAFF", "Member d'une agence"],
  ["ADMIN", "administrateur sank"],
  ["SUPERVISOR", "Superviseur"],
  ["ONEAAGENT", "Agent Onea"],
  // ["RESPONSABLEZONE", "Responsable Zone"],
  ["TECHNICALSUPPORT", "Support technique"],
]);
export const mapsGasStationAttendantRoles = new Map<string, string>([
  ["MANAGER", "Manager"],
  ["ATTENDANT", "Gérant"],
]);

export const userRoleMap = new Map<string, string>([
  ["SUDO", "super administrateur"],
  ["ADMIN", "administrateur"],
  ["MERCHANT", "Marchand"],
  ["AGENT", "agent"],
  ["DEALER", "distributeur"],
  ["GASSTATION", "station de service"],
  ["USER", "utilisateur"],
  ["AGENCY", "agence"],
  ["MANAGER", "Manager"],
  ["CASHIER", "Caissière"],
  ["SELLER", "Commerciale"],
  ["TECHNICALSUPPORT", "Support technique"],
  ["AGENCYSTAFF", "Member de l'agence"],
  ["GASSTATIONSTAFF", "Member de la station-service"],
  ["SUPERVISOR", "Superviseur"],
  ["SUPERMERCHANT", "Super-marchand"],
  ["SUPERSTATION", "Super-station"],
  ["COMPANY", "ENTREPRISE"],
  ["ATTENDANT", "Gérant"],
  ["ONEAAGENT", "Agent Onea"],
  ["COLLABORATOR", "Collaborateur"],
  ["COLLECTOR", "Collecteur"],
  ["COLLECTIONPOINT", "Point Collecte"]
  // ["RESPONSABLEZONE", "Responsable Zone"],
]);

export const accountMap = new Map<string, string>([
  ["MAIN", "Compte principal"],
  ["FUEL", "Compte carburant"],
  ["TRANSACTIONFEESACCOUNT", "Frais des transactions"],
  ["SNBLTRANSACTIONSFEESACCOUNT", "Frais SNBL PREPAID"],
  ["ONEATRANSACTIONSFEESACCOUNT", "Frais ONEA"],
  ["SNBLPOSTPAIDTRANSACTIONSFEESACCOUNT", "Frais SNBL POSTPAID"],
  ["AGRIVOUCHERTRANSACTIONSFEESACCOUNT", "Frais Agrivoucher"],
  ["AITRIMETRANSACTIONSFEESACCOUNT", "Frais airtimes"],
]);

export enum CompanyAttendantRole {
  MANAGER = "MANAGER",
  ATTENDANT = "ATTENDANT",
}

export enum UserAvailableZone {
  ZONEEST = "ZONEEST",
  ZONECENTRE = "ZONECENTRE",
  ZONEKOUDOUGOU = "ZONEKOUDOUGOU",
  ZONEOUEST = "ZONEOUEST",
}

export const mapUserAvailableZone = new Map<string, string>([
  ["ZONEEST", "Est"],
  ["ZONEOUEST", "Ouest"],
  ["ZONEKOUDOUGOU", "Koudougou"],
  ["ZONECENTRE", "Centre"],
]);

export const userAvailableZonesOptions = [
  UserAvailableZone.ZONEEST,
  UserAvailableZone.ZONEOUEST,
  UserAvailableZone.ZONEKOUDOUGOU,
  UserAvailableZone.ZONECENTRE,
];

export enum SNBLSUBSCIPTIONTYPE {
  POSTPAID = "POSTPAID",
  PREPAID = "PREPAID",
}
