import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../contexts";
import { transactionCollectionName } from "../../config";
import { db } from "../../firebase";
import { BoltIcon, CheckCircleIcon, FunnelIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import Pagination from "../../components/Pagination/Pagination";
import NoContent from "../../components/TableNoContentPage/NoContent";
import { classNames, getTimestamp, getTransactionLabel } from "../../utils";
import SankMoneyIcon from "../../CFA.png";
import moment from "moment";
import toast from "react-hot-toast";

const tableHeader = [
  "N°",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Point de vente",
  "Frais",
  "Status",
  "Date de création",
];

const CollectionPointTransactions = () => {
  const location = useLocation();
  const currentMerchant = location.state?.entity;
  const [histories, setHistories] = useState<any[]>([]);
  const [defaultLoadHistories, setDefaultLoadHistories] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { user } = useContext(UserContext);
  const [transactionTotalSum, setTransactionTotalSum] = useState<number>(0);

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const [pointOfSaleIdentity, setPointOfSaleIdentity] = useState<string>("")

  const handlepointOfSaleIdentity = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setPointOfSaleIdentity(value);
  };

  const getFilteredByDate = useCallback(async () => {
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    if (!pointOfSaleIdentity) {
      toast.error("Veuillez saisir le code du point de vente")
    } else
      await db
        .collection(transactionCollectionName)
        .orderBy("createdAt", "desc")
        .where("participantsIds", "array-contains", currentMerchant?.id)
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .where("pointOfSaleIdentity", "==", pointOfSaleIdentity)
        .get()
        .then((response) => {
          setDefaultLoadHistories(response?.size);
          setHistories(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
          let sum = 0;
          const amount = response.docs.reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(currentValue.data().amount),
            sum
          );
          setTransactionTotalSum(amount);
        });

  }, [currentMerchant?.id, pointOfSaleIdentity, seachWithEndDateValue, seachWithStartDateValue])

  const getAllHistories = useCallback(
    async (pageLimit: number) => {
      const startTime = getTimestamp(seachWithStartDateValue, true);
      const endTime = getTimestamp(seachWithEndDateValue, false);
      await db
        .collection(transactionCollectionName)
        .orderBy("createdAt", "desc")
        .where("participantsIds", "array-contains", currentMerchant?.id)
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .limit(pageLimit)
        .get()
        .then((response) => {
          setDefaultLoadHistories(response?.size);
          setHistories(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
          let sum = 0;
          const amount = response.docs.reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(currentValue.data().amount),
            sum
          );
          setTransactionTotalSum(amount);
          setPage(1);
        });
    },
    [currentMerchant?.id, seachWithEndDateValue, seachWithStartDateValue]
  );

  useEffect(() => {
    currentMerchant && getAllHistories(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Histroiques des transactions du point de collecte: {" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-red-600 from-red-900">
              {currentMerchant?.displayName} {" "}
            </span>
            <span className="text-transparent bg-clip-text bg-blue-600">
              ({transactionTotalSum} fcfa)
            </span>
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Les transactions du point de collecte
          </p>
        </div>
      </div>
      <div className="h-8" />
      <div className="flex self-end justify-end max-md:flex-wrap mb-6">
        <div className="mt-1 mr-3">
          <div>
            <label
              htmlFor="code"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Entrez l'identifiant du point de vente.
            </label>
            <input
              type="text"
              id="code"
              maxLength={6}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Ex: 0256878"
              onChange={handlepointOfSaleIdentity}
            />
          </div>
        </div>
        <div className="flex px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
          <div className="mr-1">
            <label
              htmlFor="dateDebut"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Date début
            </label>
            <input
              type="date"
              id="dateDebut"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
              onChange={handleChangeStartDateValue}
            />
          </div>
          <div className="mr-1">
            <label
              htmlFor="dateFin"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Date fin
            </label>
            <input
              type="date"
              id="dateFin"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
              onChange={handleChangeEndDtaeValue}
            />
          </div>
          <div className="flex items-center justify-center mt-5 h-14">
            <div className="flex">
              <button
                type="button"
                className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => {
                  getFilteredByDate();
                }}
              >
                <FunnelIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                  aria-hidden="true"
                />
                Filtrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:border-t lg:border-gray-200">
        <div className="mt-3 main-form">
          <div className="flex flex-col mt-8">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {histories && histories.length > 0 ? (
                        histories.map((history: any, historyIdx: number) => (
                          <tr key={historyIdx}>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(historyIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.id === history.data.senderID
                                ? history.data.receiverDisplayName
                                  ? history.data.receiverDisplayName
                                  : "-"
                                : history.data.senderDisplayName
                                  ? history.data.senderDisplayName
                                  : "-"}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.id === history.data.senderID
                                ? (history.data.receiverPhoneNumber as string)
                                : (history.data.senderPhoneNumber as string)}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {new Intl.NumberFormat().format(
                                history.data.amount
                              )}{" "}
                              Fcfa
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.id === history.data.senderID
                                ? getTransactionLabel(
                                  user?.id ?? "",
                                  history.data.transactionType,
                                  history.data.senderID
                                )
                                : getTransactionLabel(
                                  user?.id ?? "",
                                  history.data.transactionType,
                                  history.data.receiverID
                                )}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data.accountType === "MAIN" ? (
                                <img
                                  className="w-auto h-5"
                                  src={SankMoneyIcon}
                                  alt="Sank"
                                />
                              ) : (
                                // <CurrencyDollarIcon className="w-6 h-6 text-red-500" />
                                <BoltIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data?.pointOfSaleIdentity ?? ""}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data.fees as string} Fcfa
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data.isComplete ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(history.data.createdAt as Timestamp)
                                .toDate()
                                .toLocaleString()}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                  {histories && histories.length > 0 && (
                    <Pagination
                      skip={defaultLoadHistories}
                      take={defaultLoadHistories}
                      total={defaultLoadHistories}
                      collectionName={transactionCollectionName}
                      isChangedPage={false}
                      onPageChange={(pageIndex: number) => pageIndex * page}
                      onPageLimitChange={(pageLimit: number) =>
                        getAllHistories(pageLimit)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionPointTransactions;
